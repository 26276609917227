import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import "ol/ol.css";
import { Map as OlMap, View } from "ol";
import { Attribution } from "ol/control";
import OSM from "ol/source/OSM";
import GeoJSON from "ol/format/GeoJSON.js";
import Select from "ol/interaction/Select.js";
import { pointerMove } from "ol/events/condition";

import * as Proj from "ol/proj";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer.js";
import VectorSource from "ol/source/Vector.js";
import { Fill, Stroke, Style, Text } from "ol/style";
import TileJSON from "ol/source/TileJSON";
import { transformExtent, fromLonLat } from "ol/proj";
import { defaults as defaultControls } from "ol/control";
import ZoomSlider from "ol/control/ZoomSlider";
import { CountriesService } from "../../services/countries.service";

// TODO: CLEAN TECHNICAL DEBT
@Component({
  selector: "app-map",
  templateUrl: "./map.component.html",
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./map.component.scss", "../../../../node_modules/ol/ol.css"],
})
export class MapComponent implements OnInit {
  @Input() lang = "lang";

  map: OlMap;
  highlightStyle;
  hovertStyle;
  overlay;
  selectSingleClick;
  hover;
  attribution;
  //pos;
  countries = [
    {
      name: "Tunisia",
      code: "TN",
    },
    {
      name: "Morocco",
      code: "MA",
    },
    {
      name: "Libya",
      code: "LY",
    },
    {
      name: "Sudan",
      code: "SD",
    },
    {
      name: "Lebanon",
      code: "LB",
    },
    {
      name: "Iraq",
      code: "IQ",
    },
  ];

  @Output() countrySelected = new EventEmitter();

  constructor( private countriesService: CountriesService ) {}

  ngOnInit() {
    const vectorSource = new VectorSource({
      url: "assets/data/countries.geo.json",
      format: new GeoJSON(),
      wrapX: false,
      attributions: `<div>
                        <span>©</span>
                        <a href="https://uteek.net" target="_blank">UTEEK DIGITAL</a>|
                        <span>
                        These maps are sourced from
                        <a href="http://naturalearthdata.com/" target="_blank">Natural Earth</a>
                        <span>and are in the public domain</span></span>
                        </div>`,
    });
    const vector = new VectorLayer({
      source: vectorSource,
      style: (feature) => {
        style.getText().setText(this.getLanguage(feature.get("iso_a2")));
        return style;
      },
    });
    const key =
      "pk.eyJ1IjoicmFtaXRhaGVyIiwiYSI6ImNrdXptM2ZxdTNraG0ydnFycDVvdGNycGEifQ.Cy2qSeiF3-3wsXgeVuxtNA";
    this.overlay = new TileLayer({
      extent: [-572513.341856, 5211017.966314, 916327.095083, 6636950.728974],
      source: new TileJSON({
        url:
          "https://api.tiles.mapbox.com/v4/mapbox.world-black.json?secure&access_token=" +
          key,
        crossOrigin: "anonymous",
      }),
    });
    // feature.getGeometry().getExtent();
    // console.log('extent', this.mapExtent);
    const style = new Style({
      fill: new Fill({
        color: "#fff",
      }),
      stroke: new Stroke({
        color: "#10545D",
        width: 1,
      }),
      text: new Text({
        font: "10px RealHeadPro-Demi,sans-serif",
        fill: new Fill({
          color: "#000",
        }),
      }),
    });
    this.highlightStyle = new Style({
      fill: new Fill({
        color: "#540715",
      }),
      stroke: new Stroke({
        color: "#10545D",
      }),
      text: new Text({
        font: "10px RealHeadPro-Demi,sans-serif",
        fill: new Fill({
          color: "#fff",
        }),
      }),
    });
    this.hovertStyle = new Style({
      fill: new Fill({
        color: "#0f96bb",
      }),
      stroke: new Stroke({
        color: "#10545D",
      }),
      text: new Text({
        font: "10px RealHeadPro-Demi,sans-serif",
        fill: new Fill({
          color: "#000",
        }),
      }),
    });
    this.selectSingleClick = new Select({
      style: (feature) => {
        this.highlightStyle
          .getText()
          .setText(this.getLanguage(feature.get("iso_a2")));
        return this.highlightStyle;
      },
    });
    this.hover = new Select({
      style: (feature) => {
        this.highlightStyle
          .getText()
          .setText(this.getLanguage(feature.get("iso_a2")));
        return this.highlightStyle;
      },
      condition: pointerMove,
    });
    const bounds = [
      [65.007224, -164.910019],
      [65.007224, 190.71568],
    ];
    this.attribution = new Attribution({
      collapsible: false,
    });
    this.map = new OlMap({
      target: "map",
      layers: [
        /*  new TileLayer({
            source: new OSM()
          }),*/
        vector,
      ],
      controls: defaultControls({
        attribution: true,
        attributionOptions: {
          collapsible: false,
          collapsed: false,
        },
      }), // .extend([this.attribution]),
      view: new View({
        center: [2534391.53656967, 2700000.5],
        zoom: 5,
        minZoom: 3,
        maxZoom: 18,
        extent: Proj.get("EPSG:3857").getExtent(),
        // smoothExtentConstraint: true,
        multiWorld: false,
      }),
    });
    // let selected = null;
    this.map.addInteraction(this.selectSingleClick);
    this.map.addInteraction(this.hover);
    this.selectSingleClick.on("select", (event) => {
      this.handOnClick(event);
    });
  }

  handOnClick({ selected }) {
    if (selected && selected.length) {
      const feature = selected[0];
      console.log("selected", selected);
      const { name, iso_a2 } = feature.getProperties();
      //this.overlay.setExtent(feature.getGeometry().getExtent());
      // this.map.calculateExtent(feature.getGeometry().getExtent());
      this.countrySelected.emit({
        name,
        code: iso_a2,
      });
    } else {
      this.countrySelected.emit(null);
    }
  }

  public zoom(value = 1) {
    console.log("zoom", this.map.getView());
    this.map.getView().animate({
      center: this.map.getView().getCenter(),
      zoom: this.map.getView().getZoom() + value,
      duration: 333,
    });
  }

  public closeSelected() {
    console.log("test unselect:  ");
    this.selectSingleClick.getFeatures().clear(true);
    this.countrySelected.emit(null);
  }

  public clearMap() {
    this.selectSingleClick.getFeatures().clear(true);
  }

  getLanguage(code) {
    return this.countriesService.getCountry(code, this.lang);
  }
}
