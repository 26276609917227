import { Injectable } from '@angular/core';
import { Autoregister, Service, Resource, DocumentCollection, DocumentResource } from 'ngx-jsonapi';
import {FileImage} from './file.service';
export class Tag extends Resource {
  public type = 'taxonomy_term--organization_project_category';
  public attributes = {
    name: ''
  };

  public relationships = {
  };
}
@Injectable({
  providedIn: 'root'
})
@Autoregister()
export class TagsService extends Service<Tag> {
  public resource = Tag;
  public type = 'taxonomy_term--organization_project_category';
  protected path = 'organization_project_category';
}

