import {EventEmitter, Injectable} from '@angular/core';
import {Autoregister, DocumentCollection, DocumentResource, Resource, Service} from 'ngx-jsonapi';
import {Tag} from './tags.service';
import {OrganizationProject} from './projects.service';
import { FileImage } from './file.service';
export class Organization extends Resource {
  public type = 'node--organization';
  public attributes = {
    status: '',
    title: '',
    created: '',
    field_country: '',
    field_description: '',
    field_link: {
      title: '',
      uri: ''
    },
  };

  public relationships = {
    field_organization_projects : new DocumentCollection<OrganizationProject>(),
    field_image : new DocumentResource<FileImage>(),
  };
}

@Injectable({
  providedIn: 'root'
})
@Autoregister()
export class OrganizationService  extends Service<Organization> {
  public resource = Organization;
  public type = 'node--organization';
  protected  path = 'organizations';
  private nbrPage = 1;

  nbrPageChanged = new EventEmitter<number>();

  incrementPageNumber() {
    this.nbrPage = this.nbrPage + 1;
    this.nbrPageChanged.emit(this.nbrPage);
  }

  getPageNumber() {
    return this.nbrPage;
  }

  initNumberPage() {
    this.nbrPage = 1;
  }
  }

