import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-header-article',
  templateUrl: './header-article.component.html',
  styleUrls: ['./header-article.component.scss']
})
export class HeaderArticleComponent implements OnInit, OnChanges {
  @Input() countryName: string;
  @Output() closeClick = new EventEmitter();

  constructor( private changeDetector: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.changeDetector.detectChanges();
  }

  get country() {
    return this.countryName ? this.countryName : 'Leed Map';
  }

}
