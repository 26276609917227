import {Component, Input, OnChanges, OnInit, SecurityContext, ViewChild} from '@angular/core';
import {Organization, OrganizationService} from '../../services/organization.service';
import {Router} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {environment} from '../../../environments/environment';
import {OrganizationProject} from '../../services/projects.service';

@Component({
  selector: 'app-organization-project-teaser',
  templateUrl: './organization-project-teaser.component.html',
  styleUrls: ['./organization-project-teaser.component.scss']
})
export class OrganizationProjectTeaserComponent implements OnInit, OnChanges {
  @Input() project: OrganizationProject ;
  @Input() type: 'article' | 'gallery' = 'gallery';
  @Input() lang = 'en';
  @ViewChild('dynamic', {static: true}) dynamicElement;
  cleanText = '';
  organizationName;
  listOrganizationInitial;

  constructor(
    private router: Router,
    private sanitizer: DomSanitizer,
    private organizationService: OrganizationService
  ) {
  }

  ngOnInit() {
    if (this.project.attributes.field_description) {
      this.cleanText = this.stripHtml(this.project.attributes.field_description);
    }
  }

  ngOnChanges(changes) {
    this.getnitialOrganizations();
    // console.log('lang', changes);
  }



  get highlighted() {
    if (!this.project) {
      return false;
    }

  }

  get title() {
    if (!this.project) {
      return '';
    }

    return this.project.attributes.title;
  }


  get teaser() {
    this.cleanText = this.project.attributes.field_description;
    if (this.cleanText) {
      const text = this.cleanText.length > 100 ? (this.cleanText.slice(0, 100) + '...') : this.cleanText;
      return this.sanitizer.sanitize(SecurityContext.HTML, text);
    }
  }



  get field_link() {
    if (!this.project) {
      return '';
    }

    return this.project.attributes.field_link ?  this.project.attributes.field_link.uri : ''
  }


  private stripHtml(html) {
    let tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  }

  getOrganizationName() {
    let org =  [];
    if (this.listOrganizationInitial && this.project) {
      org = [].concat(...this.listOrganizationInitial.map(x => x.relationships.field_organization_projects.data.map((item: OrganizationProject) => {
         if(item.attributes.title == this.project.attributes.title) {
           return x.attributes.title;
         }
      })));
    }
    if(org.length) {
      this.organizationName = org.filter(x => !!x)[0];
    }
  }

  getImageFile(): string {
    if (this.project && this.project.relationships.field_image.data.attributes.uri) {
      return environment.base_url + this.project.relationships.field_image.data.attributes.uri.url ;
    }
    return '/assets/images/placeholder.png';

  }

  getnitialOrganizations() {
    this.organizationService.all().subscribe(items => {
      if (items.data) {
          this.listOrganizationInitial = items.data;
          this.getOrganizationName();
      }
    });
  }
}
