import {Injectable} from '@angular/core';
import * as countries from 'i18n-iso-countries';
// @ts-ignore
import germanLang from 'i18n-iso-countries/langs/de.json';
// @ts-ignore
import engLang from 'i18n-iso-countries/langs/en.json';

countries.registerLocale(germanLang);
countries.registerLocale(engLang);

@Injectable({
  providedIn: 'root'
})
export class CountriesService {

  constructor() {
  }

  getCountry(code, lang = 'de') {

    if (code) {
      if (code === 'N/A') {
        return 'Leed MAP';
      }
      return countries.getName(code, lang);
    }
    return null;
  }
}
