import {EventEmitter, Injectable} from '@angular/core';
import {Autoregister, DocumentResource, Resource, Service} from 'ngx-jsonapi';
import {Organization} from './organization.service';
import {FileImage, FileService} from './file.service';
import {Tag} from './tags.service';

export class OrganizationProject extends Resource {
  public type = 'node--sub_project';
  public attributes = {
    status: '',
    title: '',
    created: '',
    field_description: '',
    organization: '',
    field_link: {
      title: '',
      uri: ''
    },
  };

  public relationships = {
    field_image : new DocumentResource<FileImage>(),
    field_op_category : new DocumentResource<Tag>(),
  };
}


@Injectable({
  providedIn: 'root'
})
@Autoregister()
export class OrganizationProjectService extends Service<OrganizationProject> {
  public resource = OrganizationProject;
  public type = 'node--sub_project';
  protected path = 'organization-projects';
  private nbrPage = 1;
  nbrPageChanged = new EventEmitter<number>();

  incrementPageNumber() {
    this.nbrPage = this.nbrPage + 1;
    this.nbrPageChanged.emit(this.nbrPage);
  }

  getPageNumber() {
    return this.nbrPage;
  }

  initNumberPage() {
    this.nbrPage = 1;
  }
}


// organization-projects
