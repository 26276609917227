import { Component, OnInit } from '@angular/core';
import {DocumentCollection} from 'ngx-jsonapi';
import { Tag, TagsService} from '../../services/tags.service';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit {
  public tags: DocumentCollection<Tag>;
  constructor(private tagsService: TagsService) {
    tagsService
      .all({
        // include: ['books', 'photos'],
      })
      .subscribe(
        tags => {
          this.tags = tags;
        },
        error => console.error('Could not load authors :(', error)
      );
  }

  ngOnInit() {
  }

}
