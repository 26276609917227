import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {TagsComponent} from './components/tags/tags.component';
import {ComponentsModule} from './components/components.module';

const routes: Routes = [
  {
    path: 'page',
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
  },
  // { path: 'articles', component: ArticleComponent },
  // { path: 'galleries', component: GalleryComponent },
  // { path: 'tags', component: TagsComponent },
  // { path: 'authors', component: AuthorsComponent },

  {
    path: '**',
    redirectTo: 'page',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    ComponentsModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
