import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapComponent } from './map/map.component';
import { TagsComponent } from './tags/tags.component';
import { HeaderArticleComponent } from './header-article/header-article.component';
import {MatButtonModule, MatGridListModule} from '@angular/material';
import {TranslateModule} from '@ngx-translate/core';
import { OrganizationTeaserComponent } from './organization-teaser/organization-teaser.component';
import { OrganizationProjectTeaserComponent } from './organization-project-teaser/organization-project-teaser.component';
import { ModalHelpComponent } from './modal-help/modal-help.component';
import { NgxSmartModalModule, NgxSmartModalService } from 'ngx-smart-modal';

@NgModule({
  declarations: [MapComponent, TagsComponent, HeaderArticleComponent, OrganizationTeaserComponent, OrganizationProjectTeaserComponent, ModalHelpComponent],
  exports: [
    MapComponent,
    HeaderArticleComponent,
    OrganizationTeaserComponent,
    OrganizationProjectTeaserComponent,
    ModalHelpComponent
  ],
    imports: [
        CommonModule,
        MatGridListModule,
        MatButtonModule,
        TranslateModule,
        NgxSmartModalModule.forRoot(),
    ]
})
export class ComponentsModule { }
