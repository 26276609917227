// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  base_url: 'http://www.leed-initiative.org',
  url: 'https://api.leed-academy.com/',
  site_url_de: 'https://www.zeitschrift-kulturaustausch.de/de/archiv?tx_amkulturaustausch_pi1%5Bauid%5D=#UID&tx_amkulturaustausch_pi1%5Bview%5D=ARTICLE',
  site_url_en: 'https://www.zeitschrift-kulturaustausch.de/en/archive?tx_amkulturaustausch_pi1%5Bauid%5D=#UID&tx_amkulturaustausch_pi1%5Bview%5D=ARTICLE',
  author_url_de : 'https://www.zeitschrift-kulturaustausch.de/de/archiv?tx_amkulturaustausch_pi1%5Bauuid%5D=#UID&tx_amkulturaustausch_pi1%5Bview%5D=AUTHOR',
  author_url_en : 'https://www.zeitschrift-kulturaustausch.de/en/archive?tx_amkulturaustausch_pi1%5Bauuid%5D=#UID&tx_amkulturaustausch_pi1%5Bview%5D=AUTHOR'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
