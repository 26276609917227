import {Component, Input, OnChanges, OnInit, SecurityContext, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {environment} from '../../../environments/environment';
import {Organization} from '../../services/organization.service';

@Component({
  selector: 'app-organization-teaser',
  templateUrl: './organization-teaser.component.html',
  styleUrls: ['./organization-teaser.component.scss']
})
export class OrganizationTeaserComponent implements OnInit, OnChanges {
  @Input() organization: Organization ;
  @Input() type: 'article' | 'gallery' = 'gallery';
  @Input() lang = 'en';
  @ViewChild('dynamic', {static: true}) dynamicElement;
  cleanText = '';
  img_organization = ''
  url_img = '';

  constructor(
    private router: Router,
    private sanitizer: DomSanitizer
  ) {
  }

  ngOnInit() {
    this.url_img = environment.base_url
    this.cleanText = this.stripHtml(this.organization.attributes.field_description);
    this.img_organization = this.organization.relationships.field_image.data.attributes.uri.url
  }

  ngOnChanges(changes) {
    // console.log('lang', changes);
  }


  get highlighted() {
    if (!this.organization) {
      return false;
    }
  }

  get title() {
    if (!this.organization) {
      return '';
    }
    return this.organization.attributes.title;
  }

  get field_link() {
    if (!this.organization) {
      return '';
    }
    return this.organization.attributes.field_link ?  this.organization.attributes.field_link.uri : ''
  }


  get teaser() {
    const text = this.cleanText.length > 100 ? (this.cleanText.slice(0, 100) + '...') : this.cleanText;
    return this.sanitizer.sanitize(SecurityContext.HTML, text);
  }


  private stripHtml(html) {
    let tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  }


  getImageFile(): string {
    if (this.organization && this.organization.relationships.field_image.data.attributes.uri) {
      return environment.base_url + this.organization.relationships.field_image.data.attributes.uri.url ;
    }
    return '/assets/images/placeholder.png';
  }
}
